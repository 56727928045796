import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Icon from './Icon'
import { HamburgerSpin } from 'react-animated-burgers'
import { Nav, NavMobile } from './nav'

const HeaderWrapper = styled.div`
  background-color: #fff;

  @media (prefers-color-scheme: dark) {
    background-color: #17181a;
    color: #fff;
    nav ul li a {
      color: #fff;
      &:hover {
        color: #ccc;
      }
    }
  }
  @media screen and (max-width: 768px) {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25);
    position: fixed;
    width: 100%;
    z-index: 100;
  }
`

const HeaderContainer = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    padding: 10px 40px;
  }
  @media screen and (max-width: 540px) {
    padding: 10px 20px;
  }

  button {
    display: none;
  }

  @media screen and (max-width: 768px) {
    button {
      display: flex;
      padding: 11px 0;

      div span {
        background-color: #000;
        :before {
          background-color: #000;
        }
        :after {
          background-color: #000;
        }
      }
      @media screen and (max-width: 540px) {
        padding-right: 0;
      }

      @media (prefers-color-scheme: dark) {
        div span {
          background-color: #fff;
          :before {
            background-color: #fff;
          }
          :after {
            background-color: #fff;
          }
        }
      }
    }
  }
`

const StyledIconLink = styled(Link)`
  color: hsl(54, 3%, 11%);
  height: 80px;
  width: 218px;
  svg {
    height: inherit;
    width: inherit;

    @media (prefers-color-scheme: dark) {
      color: white;
    }
  }

  @media screen and (max-width: 1024px) {
    height: 60px;
    width: 164px;
  }

  @media screen and (max-width: 768px) {
    height: 40px;
    width: 109px;
  }
`

class Header extends React.Component {
  state = {
    isActive: false,
  }

  toggleNav = () => {
    this.setState(prevState => ({ isActive: !prevState.isActive }))
  }

  render() {
    return (
      <HeaderWrapper>
        <HeaderContainer isActive={this.state.isActive}>
          <StyledIconLink
            onClick={() => this.setState(prevState => ({ isActive: false }))}
            to="/"
          >
            <Icon name="logo-name" />
          </StyledIconLink>
          <Nav isActive={this.state.isActive} />
          <HamburgerSpin
            isActive={this.state.isActive}
            toggleButton={this.toggleNav}
            buttonStyle={{ zIndex: 1000 }}
            buttonWidth={30}
            barColor="#1d1d1b"
          />
        </HeaderContainer>
        <NavMobile isActive={this.state.isActive} click={this.toggleNav} />
      </HeaderWrapper>
    )
  }
}

export default Header
