import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'

import Header from './header'
import '../fonts/proxima-nova.css'

const GlobalStyle = createGlobalStyle`
html {
  box-sizing: border-box;
}
* {
  box-sizing: inherit;
}
*:before {
  box-sizing: inherit;
}
*:after {
  box-sizing: inherit;
}
body {
  font-family: 'Proxima Nova', '-apple-system','BlinkMacSystemFont','Segoe UI','Roboto','Helvetica','Arial','sans-serif','Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol', sans-serif;
  margin: 0;

}
ul {
  padding: 0;
  margin: 0;
}
li {
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 24px;
  font-size: 16px;
  color: hsl(0, 0%, 20%);
}


p {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
    font-style: normal;
    color: hsl(0, 0%, 20%);
    a {
    color: inherit;
  }
  }

h1 {
    font-size: 36px;
    margin-top: 0;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 700;
    color: hsl(0,0%,7%);
  }

h2 {
  margin-top: 0;
  margin-left: 40px;
  margin-bottom: 0rem;;
  font-size: 18px;
  font-weight: 700;
  color: hsl(0, 0%, 20%);
  /* text-transform: uppercase; */
  /* letter-spacing: 0.03em; */

  /* ::before {
    content: '';
    display: block;
    border: 1px solid hsl(0, 0%, 20%);
    position: absolute;
    margin-left: -1rem;
    margin-top: 0.5em;
    width: 10px;
  } */
}

h3 {
  margin-top: 0;
  font-size: 14px;
  margin-left: 40px;
  /* text-transform: uppercase; */
  font-weight: 600;
  color: hsl(0, 0%, 50%);
  /* letter-spacing: 0.03em; */
  padding-bottom: 30px;


}

blockquote {
  margin-bottom: 0;
	padding-top: 30px;
	padding-bottom: 10px;


  p {
    text-align: left;
    /* padding: 0 60px; */
    font-size: 26px;
    margin: 0rem;
    font-weight: 300;
    margin-bottom: 0.5rem;
    line-height: 1.5;
    /* font-style: italic; */

    ::before {
      content: '“';
      position: absolute;
      font-size: 96px;
      line-height: 0;
      margin-left: -0.35em;
      margin-top: 0.35em;
      color: hsl(0, 0%, 90%);
    }

    ::after {
      content:'”';
      font-size: 96px;
      margin-left: 0.04em;
      margin-top: 0.4em;
      line-height: 0;
      position: absolute;
      color: hsl(0, 0%, 90%);
    }
  }
}



  @media screen and (max-width: 768px) {
    p {
      font-size: 16px;
      line-height: 1.5;
    }
    h1 {
      font-size: 28px;
    }
    h2 {
      font-size: 16px;
    }
    h3 {
      font-size: 12px;
    }
}

.divider {
    height: 1px;
    padding-bottom: 0px;
    background-color: #d6d6d6;
    @media (prefers-color-scheme: dark) {
      background-color: #333;
      }

  }
@media (prefers-color-scheme: dark) {
  body {
    background-color: #17181a;
    color: #ccc;
  }
  h1 {
    color: #fff;
  }

  p {
    color:#a2a2a2;
  }
}
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 50px;

  @media screen and (max-width: 768px) {
    padding: 70px 0 0 0;
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalStyle />
        <Header siteTitle={data.site.siteMetadata.title} />
        <ContentWrapper>{children}</ContentWrapper>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
