import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const StyledNav = styled.nav`
  display: flex;
  height: 80px;
  align-items: center;

  ul {
    display: flex;
    margin: 0;
    li {
      list-style: none;
      padding: 40px 24px 0;
      margin: 0;
      font-weight: 600;
      letter-spacing: 0.05em;
      font-size: 16px;
      line-height: 26px;
      :last-child {
        padding-right: 0;
      }

      a {
        text-decoration: none;
        text-transform: uppercase;
        color: hsl(54, 3%, 11%);
        transition: linear 0.1s;
        :hover {
          color: hsl(0, 0%, 60%);
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    height: 60px;

    ul li {
      padding: 28px 10px 0;
      font-size: 12px;
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
  .active {
    color: hsl(0, 0%, 60%);
  }
`
const StyledNavMobile = styled.nav`
  display: block;
  height: ${props => (props.open ? '0' : '100vh')};
  visibility: ${props => (props.open ? 'hidden' : 'visible')};
  opacity: ${props => (props.open ? '0' : '1')};
  z-index: 10;
  background-color: #fff;
  transition: opacity 0.3091s cubic-bezier(0.32, 0.08, 0.24, 1) 0.05s,
    height 0.56s cubic-bezier(0.52, 0.16, 0.24, 1),
    visibility 0.56s cubic-bezier(0.52, 0.16, 0.24, 1);

  .active {
    color: hsl(0, 0%, 60%);
  }

  @media (prefers-color-scheme: dark) {
    background: #17181a;
  }

  ul {
    li {
      display: block;
      margin: 0 auto;
      width: 80%;
      transform: ${props =>
        props.open ? 'translateY(-50%)' : 'translateY(0)'};
      transition: transform 0.3455s cubic-bezier(0.32, 0.08, 0.24, 1) 0.02s;

      a {
        font-size: 18px;
        letter-spacing: 0.05em;
        line-height: 32px;
        font-weight: 600;
        padding: 2vh 0;
        text-transform: uppercase;
        color: hsl(54, 3%, 11%);
        text-decoration: none;
        display: inline-block;
        height: 100%;
        cursor: pointer;
        width: 100%;
        border-bottom: 1px solid #f1f1f1;

        @media (prefers-color-scheme: dark) {
          border-bottom: 1px solid #333;
        }
        :hover {
          color: hsl(0, 0%, 60%);
        }
      }
    }
  }
`
export const Nav = () => (
  <StyledNav>
    <ul>
      <li>
        <Link activeClassName="active" to="/portrait/">
          Portrait
        </Link>
      </li>
      <li>
        <Link activeClassName="active" to="/sport/">
          Sport
        </Link>
      </li>
      <li>
        <Link activeClassName="active" to="/travel/">
          Travel
        </Link>
      </li>
      <li>
        <Link activeClassName="active" to="/commercial/">
          Commercial
        </Link>
      </li>
      <li>
        <Link activeClassName="active" to="/art-direction/">
          Art Direction
        </Link>
      </li>
      <li>
        <Link activeClassName="active" to="/about/">
          About
        </Link>
      </li>
    </ul>
  </StyledNav>
)

export const NavMobile = props => (
  <StyledNavMobile open={!props.isActive}>
    <ul>
      <li>
        <Link activeClassName="active" to="/portrait/" onClick={props.click}>
          Portrait
        </Link>
      </li>
      <li>
        <Link activeClassName="active" to="/sport/" onClick={props.click}>
          Sport
        </Link>
      </li>
      <li>
        <Link activeClassName="active" to="/travel/" onClick={props.click}>
          Travel
        </Link>
      </li>
      <li>
        <Link activeClassName="active" to="/commercial/" onClick={props.click}>
          Commercial
        </Link>
      </li>
      <li>
        <Link
          activeClassName="active"
          to="/art-direction/"
          onClick={props.click}
        >
          Art Direction
        </Link>
      </li>
      <li>
        <Link activeClassName="active" to="/about/" onClick={props.click}>
          About
        </Link>
      </li>
    </ul>
  </StyledNavMobile>
)
